import { faLongArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";
// Import Swiper styles
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import CelebrityImage from "../celebrity-image";

import "swiper/css/pagination";
import { Pagination, Navigation } from "swiper";
import "swiper/css/navigation";

export default function AuthorSlider({ authors }) {
  return (
    <section className="author-slider w-100 pt-5 pb-5">
      <div className="container">
        <div className="row">
          <div className="com-md-12">
            <div className="sec-title-wrapper mb-5">
              <h2 className="sec-title fw-bolder text-uppercase">
                <Link href="/celebrity">Celebrities</Link>
              </h2>
              <span className="title-box-common"></span>
              <Link href="/celebrity">
                <a className="icon-wrapper">
                  <FontAwesomeIcon
                    icon={faLongArrowRight}
                    className="highlightIcon"
                  />
                  <span className="sr-only">View all Celebrities</span>
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">

          <div className="col-md-12">
            <Swiper
              slidesPerView = {4}  
              spaceBetween= {50}  
              // Responsive breakpoints   
              breakpoints={{
              
                  // when window width is <= 220px     
                  220: {       
                    slidesPerView: 1,
                    spaceBetween: 10     
                  },     
                  // when window width is <= 480px     
                  480: {       
                    slidesPerView: 2,       
                    spaceBetween: 20     
                  },
                  // when window width is <= 640px     
                  640: {       
                    slidesPerView: 3,       
                    spaceBetween: 30     
                  } 
              }}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
              loop={true}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination, Navigation]}
              autoplay={true}
            >
              {authors.map(({ node }) => (
                <SwiperSlide key={node.uri}>
                  <Link href={`${node.uri}`}>
                    <a className="c-auth-card">
                      <div className="auth-img-wrapper">
                        <CelebrityImage
                          firstName={node.title}
                          coverImage={node.featuredImage}
                        ></CelebrityImage>
                      </div>
                      <div className="img-wrapper-overlay">
                        {/* {node.title} */}
                        {/* <span dangerouslySetInnerHTML={{ __html: node.title }}></span> */}
                      </div>
                    </a>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}
