import BlurImage from "./BlurImage";

export default function CelebrityImage({
  firstName,
  coverImage,
  imgWidth,
  imgHeight,
}) {
  const image = (
    <BlurImage
      width={imgWidth ? imgWidth : 500}
      height={imgHeight ? imgHeight : 500}
      alt={`Image of ${firstName}`}
      src={coverImage.url ? coverImage.url : coverImage.node.guid}
      blurhash={coverImage?.blurhash}
    />
  );

  return <div className="celebrity-image">{image}</div>;
}
