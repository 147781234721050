import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";
import CoverImage from "../cover-image";

export default function HeroPost({
  title,
  coverImage,
  categories,
  excerpt,
  slug,
}) {
  return (
    <header className="header w-100 pt-5 pb-5 ">
      <div className="container">
        <div className="row">
          <div className="col-lg-1 col-sm-12">
            <div className="header-side-warper d-none d-lg-flex">
              <div className="header-side-nav">
                <ul className="">
                  <li>
                    <Link href="/celebrity">Celebrities</Link>
                  </li>
                  <li>
                    <Link href="/category/entertainment">Entertainment</Link>
                  </li>
                  <li>
                    <Link href="/category/movies">Movies</Link>
                  </li>
                  <li>
                    <Link href="/category/music">Music</Link>
                  </li>
                  <li>
                    <Link href="/category/tv-shows">TV Shows</Link>
                  </li>
                </ul>
              </div>

              <div className="arrow-warper">
                <Link href="#latest-post-sec">
                  <a>
                    <FontAwesomeIcon icon={faArrowDown} />
                    <span className="sr-only">Skip to posts</span>
                  </a>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-11 col-sm-12">
            <div className="row">
              <div className="col-lg-7 order-2 order-md-auto">
                <div className="header-content">
                  {/* <Link href={`${firstCat.uri}`}>
                    <a className="small-title">
                      <p className="m-0">
                        {firstCat.name}
                      </p>
                    </a>
                  </Link> */}
                  <h1 className="header-title"> {title} </h1>
                  <article className="mt-4 mb-4">
                    <div dangerouslySetInnerHTML={{ __html: excerpt }} />
                  </article>
                  <div className="read-more mb-4">
                    <Link href={`/${slug}`}>
                      <a
                        className="hover:underline"
                        dangerouslySetInnerHTML={{ __html: " View Full Story" }}
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 order-1 order-md-2">
                <div className="img-card-wrapper">
                  {coverImage && (
                    <CoverImage
                      title={title}
                      coverImage={coverImage}
                      slug={slug}
                      priority={true}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
