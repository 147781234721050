import parse from "html-react-parser";

export default function SEOMeta({ seoData = null, desc = null, type = null }) {
  let metaRobotsNoindex;
  let metaRobotsNofollow;
  let metaDesc;

  metaRobotsNoindex = seoData?.metaRobotsNoindex;
  if (!metaRobotsNoindex || metaRobotsNoindex == undefined) {
    metaRobotsNoindex = "noindex";
  }
  if (
    (!metaRobotsNoindex || metaRobotsNoindex == undefined) &&
    (type == "author" ||
      type == "landing" ||
      type == "celebrity" ||
      type == "category")
  ) {
    metaRobotsNoindex = "index";
  }

  metaRobotsNofollow = seoData?.metaRobotsNofollow;
  if (!metaRobotsNofollow || metaRobotsNofollow == undefined) {
    metaRobotsNofollow = "nofollow";
  }
  if (
    (!metaRobotsNofollow || metaRobotsNofollow == undefined) &&
    (type == "author" ||
      type == "landing" ||
      type == "celebrity" ||
      type == "category")
  ) {
    metaRobotsNofollow = "follow";
  }

  metaDesc = seoData?.metaDesc;
  if (type == "landing") {
    metaDesc = desc;
  }
  if (type == "tag" || type == "profession" || type == "celebsListing") {
    metaRobotsNoindex = "index";
    metaRobotsNofollow = "follow";
    metaDesc = desc;
  }

  if (type == "search") {
    metaRobotsNoindex = "noindex";
    metaRobotsNofollow = "nofollow";
    metaDesc = 'Search Page';
  }

  const canonical = seoData?.canonical;
  const opengraphType = seoData?.opengraphType;
  const opengraphTitle = seoData?.opengraphTitle;
  const opengraphDescription = seoData?.opengraphDescription;
  const opengraphUrl = seoData?.opengraphUrl;
  const articleModifiedTime = seoData?.opengraphModifiedTime;
  const openGraphImageURL = seoData?.opengraphImage?.sourceUrl;

  let seoHTML = "";
  let jsonLdString = "";

  try {
    seoHTML = (seoData?.fullHead ?? ("" as string))
      .replace("/\n/g", "")
      .replace("/\t/g", "");

    const jsonLdRegEx =
      /<script type="application\/ld\+json" class="yoast-schema-graph">.*<\/script>/g;
    jsonLdString = seoHTML.match(jsonLdRegEx)?.[0] ?? "";
    jsonLdString = jsonLdString
      .replace(
        '<script type="application/ld+json" class="yoast-schema-graph">',
        ""
      )
      .replace("</script>", "");
    if (jsonLdString) jsonLdString = JSON.stringify(JSON.parse(jsonLdString));

    seoHTML = seoHTML.replace(jsonLdRegEx, "");
  } catch (error) {
    console.log(error);
  }

  //console.log(type);

  return (
    <>
      {/* {seoHTML} */}
      <div className="seoInfo" style={{ display: "none" }}>
        {seoHTML}
      </div>

      <meta name="description" content={metaDesc} />
      {/* <link rel="canonical" href={canonical} /> */}

      <meta
        name="robots"
        content={`${metaRobotsNoindex},${metaRobotsNofollow}, max-image-preview:large, max-snippet:-1, max-video-preview:-1`}
      />

      {/* <meta property="og:locale" content="en_US" />
      <meta property="og:type" content={opengraphType} />
      <meta property="og:title" content={opengraphTitle} />
      <meta property="og:description" content={opengraphDescription} />
      <meta property="og:url" content={opengraphUrl} />
      <meta property="og:site_name" content="Gossipment" />
      <meta property="article:author" content="Gossipment" />
      <meta property="article:published_time" content={articleModifiedTime} />
      <meta property="article:modified_time" content={articleModifiedTime} />
      <meta name="twitter:card" content="summary" />
      <meta
        name="twitter:image"
        content={type == "landing" ? "/favicon/logo.png" : openGraphImageURL}
      />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content="Entertainment s Archives" />
      <meta property="og:description" content="" />
      <meta property="og:url" content="https://gossipment.com/category/entertainment/" />
      <meta property="og:site_name" content="Gossipment: Celebrity Gossip &amp; Entertainment News" />
      <meta name="twitter:card" content="summary_large_image" /> */}


      <meta name="spr-verification" content="85fc792d2fa829q" />
      <meta name="98b14900b2cecec0ce177c2a7a6842ea" content=""/>
      <meta name='outreach_verification' content='atbR1uubJ6hkUKxXa6gD' />

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: jsonLdString,
        }}
      />

      {parse(seoHTML)}
    </>
  );
}
