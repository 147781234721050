import Link from "next/link";
import CoverImage from "../cover-image";

export default function BlogPreview({ title, categories, coverImage, slug }) {
  const firstCat = categories.edges[0].node;

  return (
    <Link href={`/${slug}`}>
      <a className="c-card-one">
        {coverImage && <CoverImage title={title} coverImage={coverImage} />}
        <p className="c-card-cat-title p-0 m-0">
          <Link href={`${firstCat.uri}`}>
            <a className="d-inline-block text-uppercase py-1 m-0">
              {firstCat.name}
            </a>
          </Link>
        </p>
        <div className="c-card-title-wrapper">
          <h3
            className="title-content"
            dangerouslySetInnerHTML={{ __html: title }}
          ></h3>
        </div>
      </a>
    </Link>
  );
}
