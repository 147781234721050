import cn from "classnames";
import Link from "next/link";
import BlurImage from "./BlurImage";

export default function CoverImage({
  title,
  coverImage,
  slug = null,
  priority = false,
}) {
  const image = (
    <BlurImage
      width={1000}
      height={1200}
      alt={`Cover Image for ${title}`}
      src={coverImage?.node?.sourceUrl}
      blurhash={coverImage?.node?.blurhash}
      priority={priority}
      className={cn("shadow-small", {
        "hover:shadow-medium transition-shadow duration-200": slug,
      })}
    />
  );

  return (
    <div className="sm:mx-0">
      {slug ? (
        <Link href={`/${slug}`}>
          <a className="d-inline-block" aria-label={title}>{image}</a>
        </Link>
      ) : (
        image
      )}
    </div>
  );
}
