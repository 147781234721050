import Link from "next/link";
import CoverImage from "../cover-image";
import Date from "../date";
export default function AnimatedBlogPreview({
  title,
  coverImage,
  slug,
  date,
}) {
  return (
    <Link href={`/${slug}`}>
      <a className="c-card-one">
        {coverImage && <CoverImage title={title} coverImage={coverImage} />}
        <div className="c-card-title-wrapper mt-4">
          <h3
            className="title-content"
            dangerouslySetInnerHTML={{ __html: title }}
          ></h3>
        </div>
        <p className="post-date mt-2">
          <span>
            <Date dateString={date} />
          </span>

          {/* <ReadingTime content={content} /> */}
        </p>
      </a>
    </Link>
  );
}
