import dynamic from "next/dynamic.js";
import Head from "next/head";
import { useRouter } from "next/router";
import AuthorSlider from "../components/Author/author-slider.jsx";
import Layout from "../components/layout";
import HeroPost from "../components/Layout/HeroPost";
import PostLayout from "../components/Layout/PostLayout";
import SEOMeta from "../components/SEOMeta";
//import generateRSS from "../lib/generateRssFeed";

import {
  getAllCelebrity,
  getAllPostsForHome,
  getFrontPageContent,
  getHomeCatData,
  getLatestBlogs,
  getPopularBlogs,
  getTrendingBlogs,
  resolveAll,
  SearchPost,
} from "../lib/api";

export default function Index({
  allPosts,
  latestBlogsData,
  popularBlogsData,
  trendingBlogsData,
  celebrityCatData,
  entertainmentCatData,
  allCeleb,
  pageCont,
}) {
  const heroPost = allPosts?.edges[0]?.node;
  const latestPosts = latestBlogsData?.edges;
  const popularPosts = popularBlogsData?.edges;
  const trendingPosts = trendingBlogsData?.edges;
  const celebrityCategoryData = celebrityCatData?.[0]?.node;
  const entertainmentCategoryData = entertainmentCatData?.[0]?.node;
  const celebs = allCeleb;

  type BlogsData = { edges: { node: SearchPost }[] };
  const { query } = useRouter();

  const searchString = typeof query.s == "object" ? query.s[0] : query.s;

  const pageData = pageCont?.edges[0]?.node;
  //console.log(searchString);

  const SearchData = dynamic(() => import("../components/SearchData"));

  return (
    <>
      <Layout>
        {/* {searchString &&
          <>
            <title>Gossipment: Search Page</title>
            <meta
              name="robots"
              content="noindex, nofollow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
            />
            <meta name="description" content="Gossipment Search" />          
          </>
        }

        {!searchString && 
        } */}

        <Head>
          <title>{pageData?.seo.title}</title>
          <SEOMeta
            seoData={pageData.seo}
            type="landing"
            desc="Gossipment is a leading source of reliable Blogs, news and guides. Get hard-to-find insights and advice on Blogs from industry-specific experts."
          />
        </Head>

        {searchString && <SearchData searchString={searchString} />}

        {heroPost && (
          <HeroPost
            title={heroPost.title}
            coverImage={heroPost.featuredImage}
            categories={heroPost.categories}
            slug={heroPost.slug}
            excerpt={heroPost.excerpt}
          />
        )}

        {/* Latest Posts */}
        {latestPosts.length > 0 && (
          <PostLayout
            posts={latestPosts}
            title="What’s New?"
            sectionClass="latest-post-sec w-100 pt-5 pb-5"
            accent="#f5f5f5"
            accentLight="#fff"
            sectionId="latest-post-sec"
            uri="/"
          />
        )}

        {/* Popular posts */}
        {popularPosts.length > 0 && (
          <PostLayout
            posts={popularPosts}
            title="What’s Trending?"
            sectionClass="popular-post-sec w-100 pt-5 pb-5"
            accent="#e5f3fe"
            accentLight="#fff"
            layoutType="animated"
            uri="/"
          />
        )}

        {/* Trending Posts */}
        {trendingPosts.length > 0 && (
          <PostLayout
            posts={trendingPosts}
            title="What’s Hot?"
            sectionClass="trending-post-sec w-100 pt-5 pb-5"
            accent="#0b0d10"
            accentLight="#fff"
            layoutType="common"
            uri="/"
          />
        )}

        {/* Celebrity Posts */}
        <PostLayout
          data={celebrityCategoryData}
          title="Juicy Celeb Gossip"
          sectionClass="celebrity-post-sec w-100 pt-5 pb-5"
          layoutType="animated"
        />

        {/* Author Slider */}
        {celebs.length > 0 && <AuthorSlider authors={celebs} />}

        {/* Entertainment Posts */}
        <PostLayout
          layoutType="animated"
          title="Page Six"
          data={entertainmentCategoryData}
          sectionClass="entertain-sec w-100 pt-5 pb-5"
        />        

      </Layout>
    </>
  );
}

export async function getStaticProps({ preview = false }) {
  const limit = 6;

  const slug = "Front Page";

  

  try {
    const [
      allPosts,
      latestBlogsData,
      popularBlogsData,
      trendingBlogsData,
      categoryCelebrities,
      categoryEntertainment,
      allCeleb,
      pageData,
      //feedData,
    ] = await resolveAll([
      getAllPostsForHome(preview),
      getLatestBlogs(limit, preview),
      getPopularBlogs(limit, preview),
      getTrendingBlogs(limit, preview),
      getHomeCatData(limit, "celebrities", preview),
      getHomeCatData(limit, "entertainment", preview),
      getAllCelebrity(),
      getFrontPageContent(slug, preview),
      //generateRSS()
    ]);

    return {
      props: {
        allPosts,
        latestBlogsData,
        popularBlogsData,
        trendingBlogsData,
        celebrityCatData: categoryCelebrities.edges,
        entertainmentCatData: categoryEntertainment.edges,
        preview,
        allCeleb,
        pageCont: pageData,
      },
      revalidate: 60 * 60 * 24,
    };
  } catch (error) {
    console.log({ error });

    return {
      props: {},
    };
  }
}
