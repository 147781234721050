import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";
import AnimatedBlogPreview from "./AnimatedBlogPreview";
import BlogPreview from "./BlogPreview";
import DefaultBlogPreview from "./DefaultBlogPreview";

function PostLayout(props) {
  const categoryData = props.data;
  const posts = props.posts ?? categoryData.posts.edges;

  const accentBg =
    props.accentLight ?? props.data.categoryColor.accentBackground;
  const accentColor = props.accent ?? props.data.categoryColor.accentColor;

  const sectionId =
    props.sectionId ?? props.title.toLowerCase().replace(/ /g, "-") + "-sec";

  const categoryURL = props.uri ?? categoryData.uri;

  return (
    <section
      id={sectionId}
      className={props.sectionClass}
      style={{ "--accent": accentColor, "--accent-light": accentBg }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex justify-between align-items-center mb-5">
              <div className="sec-title-wrapper">
                <h2 className="sec-title fw-bolder text-uppercase">
                  {categoryURL && categoryURL != '/' && <Link href={categoryURL}>{props.title}</Link> }
                  {!categoryURL || categoryURL == '/' && props.title }
                </h2>
                <span className="title-box-common"></span>
              </div>

              {categoryURL && categoryURL != '/' && 
                <Link href={categoryURL}>
                  <a className="icon-wrapper" style={{ color: accentColor }}>
                    <FontAwesomeIcon icon={faArrowRightLong} />
                    <span className="sr-only">View all {props.title} posts</span>
                  </a>
                </Link>
              }
            </div>
          </div>
          <div className="col-md-12">
            <div className="post-grid-wrapper">
              {props.layoutType == "animated"
                ? posts.map(({ node }) => (
                    <AnimatedBlogPreview
                      key={`animated-${node.slug}`}
                      title={node.title}
                      categories={node.categories}
                      coverImage={node.featuredImage}
                      date={node.date}
                      author={node.author}
                      slug={node.slug}
                      excerpt={node.excerpt}
                    />
                  ))
                : props.layoutType == "common"
                ? posts.map(({ node }) => (
                    <DefaultBlogPreview
                      key={`default-${node.slug}`}
                      title={node.title}
                      categories={node.categories}
                      coverImage={node.featuredImage}
                      date={node.date}
                      author={node.author}
                      slug={node.slug}
                      excerpt={node.excerpt}
                    />
                  ))
                : posts.map(({ node }) => (
                    <BlogPreview
                      key={`blog-${node.slug}`}
                      title={node.title}
                      categories={node.categories}
                      coverImage={node.featuredImage}
                      date={node.date}
                      author={node.author}
                      slug={node.slug}
                      excerpt={node.excerpt}
                    />
                  ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PostLayout;
